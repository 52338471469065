import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords, RiskThreatDto } from '@shared/classes';
import { RelationsService, ViewModeService } from '@shared/services';
import { RiskThreatDataService } from '../../services/data/risk-threat-data.service';

@Component({
  selector: 'app-risk-threat-item-form',
  templateUrl: './risk-threat-item-form.component.html',
  styleUrl: './risk-threat-item-form.component.scss',
})
export class RiskThreatItemFormComponent extends BaseForm<RiskThreatDto> implements OnInit {
  constructor(
    private requestService: RiskThreatDataService,
    private relationsService: RelationsService,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, ModuleKeywords.RiskThreat);
  }

  ngOnInit(): void {}

  getData() {
    // return this.formGroup.getRawValue();
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      applicableTo: new FormControl([]),
      description: new FormControl(null),
    });
  }
}
